import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './VerificationIcon.css';

export const VerificationIcon = ({ verified }) => {
  return (
    <span className={`verification-icon ${verified ? 'verified' : ''}`}>
      <FontAwesomeIcon icon={faCheckCircle} />
    </span>
  );
};
