import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './Payment.css';

function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    if (location.state && location.state.selectedPlan) {
        console.log(location.state.selectedPlan)
      setSelectedPlan(location.state.selectedPlan);
    }
  }, [location]);

  const plans = [
    { name: 'Free', price: 0.01 },
    { name: 'Pro', price: 199.00 },
    { name: 'Business', price: 299.00 },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setPaymentStatus('');
  };

  const initialOptions = {
    "client-id": "AVDscJP_kV8nGXnffnwVsXc-oldqGjPgtkV_jAhZbTzAmUTh3xlVTOZA2dFob3eC2u63gL6hM-3dL0mI",
    currency: "BRL",
    intent: "capture",
  };

  return (
    <div className="payment-container">
      <h1 className="payment-title">Finalizar Pagamento</h1>
      <div className="plan-selection">
        <h2>Selecione seu plano:</h2>
        <div className="plan-buttons">
          {plans.map((plan) => (
            <button
              key={plan.name}
              className={`plan-button ${selectedPlan && selectedPlan.name === plan.name ? 'selected' : ''}`}
              onClick={() => handlePlanSelect(plan)}
            >
              {plan.name} - R$ {plan.price}
            </button>
          ))}
        </div>
      </div>
      {selectedPlan && (
        <div className="payment-details">
          <h2>Detalhes do Pagamento</h2>
          <p>Plano selecionado: {selectedPlan.name}</p>
          <p>Valor: R$ {selectedPlan.value}</p>
          <div className="paypal-button-container">
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                            value: selectedPlan.value.toFixed(2),
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    setPaymentStatus('Pagamento aprovado! Obrigado pela sua compra.');
                  });
                }}
                onError={(err) => {
                  setPaymentStatus('Erro no pagamento. Por favor, tente novamente.');
                  console.error('PayPal Error:', err);
                }}
              />
            </PayPalScriptProvider>
          </div>
          {paymentStatus && <p className="payment-status">{paymentStatus}</p>}
        </div>
      )}
      <button className="back-button" onClick={() => navigate('/plans')}>Voltar para Planos</button>
    </div>
  );
}

export default Payment;