import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Plans.css';

function Plans() {
  const [hoveredPlan, setHoveredPlan] = useState(null);
  const navigate = useNavigate();

  const plans = [
    {
      name: 'Pro',
      price: 'R$ 149',
      value: 149.00,
      period: '/mês',
      features: [
        'Otimização de cadastro (até 500 produtos/mês)',
        'Geração de descrições avançadas',
        'Acesso via API',
        'Validação de imagens',
        'Classificação inteligente básica',
        'Suporte prioritário',
      ],
      nonFeatures: [
        'Análise de dados avançada',
      ],
      recommended: true,
      color: '#00d2ff',
    },
    {
      name: 'Business',
      price: 'R$ 349',
      value: 349.00,
      period: '/mês',
      features: [
        'Otimização de cadastro (até 1000 produtos/mês)',
        'Geração de descrições premium',
        'Acesso via API',
        'Validação de imagens avançada',
        'Classificação inteligente avançada',
        'Análise de dados avançada',
        'Suporte 24/7',
      ],
      nonFeatures: [],
      recommended: false,
      color: '#3a7bd5',
    },
    {
      name: 'Enterprise',
      price: 'R$ 799',
      value: 799.00,
      period: '/mês',
      features: [
        'Solução personalizada para grandes empresas',
        'Volume de cadastros personalizado',
        'Todas as funcionalidades do plano Business',
        'API dedicada e integrações personalizadas',
        'Painel de gerenciamento totalmente customizável',
        'Análise de dados avançada e relatórios personalizados',
        'SLA personalizado e suporte prioritário',
      ],
      nonFeatures: [],
      recommended: false,
      color: '#3a7bd5',
    },
  ];

  const handleSelectPlan = (plan) => {
    navigate('/payment', { state: { selectedPlan: plan } });
  };

  return (
    <div className="plans-container">
      <h1 className="plans-title">Escolha o Plano Ideal para Você</h1>
      <div className="plans-grid">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`plan ${plan.recommended ? 'recommended' : ''}`}
            onMouseEnter={() => setHoveredPlan(index)}
            onMouseLeave={() => setHoveredPlan(null)}
            style={{
              '--plan-color': plan.color,
              transform: hoveredPlan === index ? 'translateY(-10px)' : 'translateY(0)',
            }}
          >
            {plan.recommended && <div className="recommended-badge">Mais Popular</div>}
            <div className="plan-header">
              <h2>{plan.name}</h2>
              <div className="price-container">
                <span className="price">{plan.price}</span>
                <span className="period">{plan.period}</span>
              </div>
            </div>
            <div className="plan-content">
              <ul className="features">
                {plan.features.map((feature, i) => (
                  <li key={i} className="feature-item">
                    <span className="icon checkmark"></span>
                    <span>{feature}</span>
                  </li>
                ))}
                {plan.nonFeatures.map((nonFeature, i) => (
                  <li key={i} className="feature-item non-feature">
                    <span className="icon cross"></span>
                    <span>{nonFeature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <button onClick={() => handleSelectPlan(plan)} className="select-plan">
              Selecionar Plano
              <span className="button-hover"></span>
            </button>
          </div>
        ))}
      </div>
      <div className="enterprise-plan">
        <h2>Enterprise</h2>
        <p>Solução personalizada para grandes empresas e necessidades específicas</p>
        <div className="enterprise-features">
          <ul>
            <li><span className="icon checkmark"></span>Volume de cadastros personalizado</li>
            <li><span className="icon checkmark"></span>Todas as funcionalidades do plano Business</li>
            <li><span className="icon checkmark"></span>API dedicada e integrações personalizadas</li>
            <li><span className="icon checkmark"></span>Painel de gerenciamento totalmente customizável</li>
            <li><span className="icon checkmark"></span>Análise de dados avançada e relatórios personalizados</li>
            <li><span className="icon checkmark"></span>SLA personalizado e suporte prioritário</li>
          </ul>
        </div>
        <button onClick={() => navigate('/contact')} className="enterprise-cta">Solicitar Proposta</button>
      </div>
    </div>
  );
}

export default Plans;
