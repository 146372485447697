import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Settings, Package, Image, FileText, Edit2, Trash2, Check, X } from 'lucide-react';
import './Configurations.css';

const menuItems = [
  { id: 'ia', label: 'Parâmetros Padrões da IA', icon: Settings },
  { id: 'produtos', label: 'Otimização de Produtos', icon: Package },
  { id: 'imagens', label: 'Análise de Imagens', icon: Image },
  { id: 'cadastro', label: 'Avaliação de Cadastro', icon: FileText },
];

function Configurations() {
  const [activeMenu, setActiveMenu] = useState('ia');
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingParam, setEditingParam] = useState(null);

  useEffect(() => {
    if (activeMenu !== 'ia') {
      fetchParameters();
    }
  }, [activeMenu]);

  const fetchParameters = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`http://localhost:5000/api/parametros`);
      setParameters(response.data);
    } catch (err) {
      setError('Erro ao carregar parâmetros. Por favor, tente novamente.');
    }
    setLoading(false);
  };

  const handleEdit = (param) => {
    setEditingParam({ ...param });
  };

  const handleSave = () => {
    const updatedParameters = parameters.map(p => 
      p.param === editingParam.param ? editingParam : p
    );
    setParameters(updatedParameters);
    setEditingParam(null);
  };

  const handleCancel = () => {
    setEditingParam(null);
  };

  const handleDelete = (param) => {
    const updatedParameters = parameters.filter(p => p.param !== param.param);
    setParameters(updatedParameters);
  };

  const handleInputChange = (field, value) => {
    setEditingParam(prev => ({ ...prev, [field]: value }));
  };

  const renderParameterFields = () => {
    return parameters.map((param) => (
      <div key={param.param} className="parameter-row">
        {editingParam && editingParam.param === param.param ? (
          <>
            <div className="parameter-edit-content">
              <div className="parameter-edit-header">
                <input
                  type="text"
                  value={editingParam.field}
                  onChange={(e) => handleInputChange('field', e.target.value)}
                  className="edit-input"
                />
                <input
                  type="text"
                  value={editingParam.type}
                  onChange={(e) => handleInputChange('type', e.target.value)}
                  className="edit-input type-input"
                />
              </div>
              <textarea
                value={editingParam.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                className="edit-input description-input"
              />
            </div>
            <div className="parameter-actions">
              <button className="icon-button apply" onClick={handleSave}>
                <Check size={18} />
              </button>
              <button className="icon-button cancel" onClick={handleCancel}>
                <X size={18} />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="parameter-content">
              <div className="parameter-header">
                <span className="parameter-name">{param.field}</span>
                {activeMenu !== 'ia' && <span className="parameter-type">{param.type}</span>}
              </div>
              <p className="parameter-description">{param.description}</p>
            </div>
            <div className="parameter-actions">
              <button className="icon-button edit" onClick={() => handleEdit(param)}>
                <Edit2 size={18} />
              </button>
              <button className="icon-button delete" onClick={() => handleDelete(param)}>
                <Trash2 size={18} />
              </button>
            </div>
          </>
        )}
      </div>
    ));
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'ia':
        return (
          <div className="content-wrapper">
            <h2>Parâmetros Padrões da IA</h2>
            <div className="parameters-list">
              <div className="parameter-row">
                <div className="parameter-content">
                  <div className="parameter-header">
                    <span className="parameter-name">Modelo de IA</span>
                  </div>
                  <div className="parameter-value">
                    <select id="model" className="input">
                      <option value="gpt-4">GPT-4</option>
                      <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="parameter-row">
                <div className="parameter-content">
                  <div className="parameter-header">
                    <span className="parameter-name">Temperatura</span>
                  </div>
                  <div className="parameter-value">
                    <input type="range" id="temperature" min="0" max="1" step="0.1" className="slider" />
                    <span className="slider-value">0.5</span>
                  </div>
                </div>
              </div>
              <div className="parameter-row">
                <div className="parameter-content">
                  <div className="parameter-header">
                    <span className="parameter-name">Máximo de Tokens</span>
                  </div>
                  <div className="parameter-value">
                    <input type="number" id="maxTokens" className="input" placeholder="1000" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'produtos':
      case 'imagens':
      case 'cadastro':
        return (
          <div className="content-wrapper">
            <h2>{menuItems.find(item => item.id === activeMenu).label}</h2>
            {loading ? (
              <div className="loading-spinner"></div>
            ) : error ? (
              <p className="error">{error}</p>
            ) : (
              <div className="parameters-list">
                {renderParameterFields()}
              </div>
            )}
          </div>
        );
      default:
        return <div>Selecione uma opção do menu</div>;
    }
  };

  const handleSaveAll = () => {
    const formData = {};
    parameters.forEach(param => {
      formData[param.field] = param.value;
    });

    const jsonOutput = {
      type: "object",
      properties: formData
    };

    console.log(JSON.stringify(jsonOutput, null, 2));
    // Aqui você pode enviar os dados para o servidor ou fazer o que for necessário
  };

  return (
    <div className="config-screen">
      <div className="config-sidebar">
        <h1>Configurações</h1>
        <nav>
          {menuItems.map((item) => (
            <button
              key={item.id}
              className={`menu-item ${activeMenu === item.id ? 'active' : ''}`}
              onClick={() => setActiveMenu(item.id)}
            >
              <span className="menu-icon">
                <item.icon size={20} />
              </span>
              {item.label}
            </button>
          ))}
        </nav>
      </div>
      <div className="config-content">
        {renderContent()}
        <button className="save-button" onClick={handleSaveAll}>Salvar Configurações</button>
      </div>
    </div>
  );
}

export default Configurations;