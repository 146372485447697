import React, { useState } from "react";
import "./TagList.css";

const TagList = ({ tags, setTags, maxVisible = 5, placeholder }) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = (e) => {
    e.preventDefault();
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const visibleTags = tags.slice(0, maxVisible);
  const extraTagsCount = tags.length > maxVisible ? tags.length - maxVisible : 0;

  return (
    <div className="tag-list">
      <form onSubmit={addTag}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder || "Adicionar uma tag"}
        />
        <button className="button-add">+</button>
      </form>
      <div className="tags">
        {visibleTags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <button onClick={() => removeTag(tag)}>x</button>
          </span>
        ))}
        {extraTagsCount > 0 && (
          <span className="tag extra-tags">
            +{extraTagsCount}
          </span>
        )}
      </div>
    </div>
  );
};

export default TagList;
