import React, { useState } from 'react';
import './ConfiguracoesIA.css';

function ConfiguracoesIA() {
  const [config, setConfig] = useState({
    maxTokens: 500,
    temperature: 0.7,
    topP: 1.0,
    frequenciaPenalidade: 0.0,
    presencaPenalidade: 0.0,
    otimizarCadastro: true,
    gerarDescricao: true,
    validarImagens: true,
    categorizacaoInteligente: true,
    analiseAvancada: true,
  });

  const [modelFields, setModelFields] = useState([
    { fieldName: '', fieldType: 'string', fieldComment: '' }
  ]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setConfig({
      ...config,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleModelChange = (index, field, value) => {
    const updatedFields = [...modelFields];
    updatedFields[index][field] = value;
    setModelFields(updatedFields);
  };

  const addField = () => {
    setModelFields([...modelFields, { fieldName: '', fieldType: 'string', fieldComment: '' }]);
  };

  const removeField = (index) => {
    const updatedFields = [...modelFields];
    updatedFields.splice(index, 1);
    setModelFields(updatedFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Configurações salvas:', config);
    console.log('Modelo de retorno:', modelFields);
    // Lógica de salvar as configurações
  };

  return (
    <div className="configuracoes-ia">
      <h1>Configurações de IA</h1>
      <form onSubmit={handleSubmit}>
        {/* Seção: Parâmetros de Geração */}
        <div className="config-section">
          <h2>Parâmetros de Geração</h2>
          <div className="config-grid">
            <label>
              Max Tokens
              <input
                type="number"
                name="maxTokens"
                value={config.maxTokens}
                onChange={handleInputChange}
                min="100"
                max="2048"
              />
            </label>

            <label>
              Temperatura
              <input
                type="number"
                name="temperature"
                value={config.temperature}
                onChange={handleInputChange}
                step="0.1"
                min="0"
                max="1"
              />
            </label>

            <label>
              Top P
              <input
                type="number"
                name="topP"
                value={config.topP}
                onChange={handleInputChange}
                step="0.1"
                min="0"
                max="1"
              />
            </label>

            <label>
              Frequência Penalidade
              <input
                type="number"
                name="frequenciaPenalidade"
                value={config.frequenciaPenalidade}
                onChange={handleInputChange}
                step="0.1"
                min="0"
                max="2"
              />
            </label>

            <label>
              Presença Penalidade
              <input
                type="number"
                name="presencaPenalidade"
                value={config.presencaPenalidade}
                onChange={handleInputChange}
                step="0.1"
                min="0"
                max="2"
              />
            </label>
          </div>
        </div>

        {/* Seção: Funcionalidades */}
        <div className="config-section">
          <h2>Funcionalidades de IA</h2>
          <div className="config-grid">
            <label>
              <input
                type="checkbox"
                name="otimizarCadastro"
                checked={config.otimizarCadastro}
                onChange={handleInputChange}
              />
              Otimizar Cadastro de Produtos
            </label>

            <label>
              <input
                type="checkbox"
                name="gerarDescricao"
                checked={config.gerarDescricao}
                onChange={handleInputChange}
              />
              Geração de Descrições Persuasivas
            </label>

            <label>
              <input
                type="checkbox"
                name="validarImagens"
                checked={config.validarImagens}
                onChange={handleInputChange}
              />
              Validação e Otimização de Imagens
            </label>

            <label>
              <input
                type="checkbox"
                name="categorizacaoInteligente"
                checked={config.categorizacaoInteligente}
                onChange={handleInputChange}
              />
              Categorização Inteligente
            </label>

            <label>
              <input
                type="checkbox"
                name="analiseAvancada"
                checked={config.analiseAvancada}
                onChange={handleInputChange}
              />
              Análise Avançada de Dados
            </label>
          </div>
        </div>

        {/* Seção: Modelo de Retorno */}
        <div className="config-section">
          <h2>Definir Modelo de Retorno</h2>
          {modelFields.map((field, index) => (
            <div key={index} className="model-grid">
              <input
                type="text"
                placeholder="Nome do Campo"
                value={field.fieldName}
                onChange={(e) => handleModelChange(index, 'fieldName', e.target.value)}
              />
              <select
                value={field.fieldType}
                onChange={(e) => handleModelChange(index, 'fieldType', e.target.value)}
              >
                <option value="string">String</option>
                <option value="number">Number</option>
                <option value="boolean">Boolean</option>
                <option value="array">Array</option>
                <option value="object">Object</option>
              </select>
              <input
                type="text"
                placeholder="Comentário"
                value={field.fieldComment}
                onChange={(e) => handleModelChange(index, 'fieldComment', e.target.value)}
              />
              <button type="button" onClick={() => removeField(index)}>Remover</button>
            </div>
          ))}
          <button type="button" onClick={addField}>Adicionar Campo</button>
        </div>

        <div className="explore-more">
          <button className="explore-button" type="submit">
            Salvar Configurações
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfiguracoesIA;
