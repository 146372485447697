import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"; // Importa o ícone de lixeira
import "./ImageGrid.css";

function ImageGrid({ images, onDelete }) {
  return (
    <div className="image-grid">
      {images.length === 0 ? (
        <p></p>
      ) : (
        images.map((image, index) => (
          <div className="image-item" key={index}>
            <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
            <div className="overlay">
              <FontAwesomeIcon 
                icon={faTrashAlt} 
                className="delete-icon" 
                onClick={() => onDelete && onDelete(index)} 
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ImageGrid;
