import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBars, 
  faTachometerAlt, 
  faBoxOpen, 
  faTh, 
  faChartLine,
  faUserCog,
  faTools,
  faQuestionCircle,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ZenithLogo } from '../../../assets/svg/icons/ZenithLogo.svg';
import { ReactComponent as CerebroDigital } from '../../../assets/svg/icons/CerebroDigital.svg';
import { ReactComponent as CircuitoAscedente } from '../../../assets/svg/icons/CircuitoAscedente.svg';
import { ReactComponent as Constelacao } from '../../../assets/svg/icons/Constelacao.svg';
import { ReactComponent as Cubo3d } from '../../../assets/svg/icons/Cubo3d.svg';
import { ReactComponent as PicoMontanha } from '../../../assets/svg/icons/PicoMontanha.svg';
import "./Sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const location = useLocation();

  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const menuItems = [
    { icon: faTachometerAlt, text: "Dashboard", path: "/admin/dashboard" },
    { 
      icon: faBoxOpen, 
      text: "Produtos", 
      path: "/admin/produtos",
      submenu: [
        { text: "Listar Produtos", path: "/admin/produtos/listar" },
        { text: "Adicionar Produto", path: "/admin/produtos/adicionar" },
        { text: "Categorias", path: "/admin/produtos/categorias" }
      ]
    },
    { icon: faChartLine, text: "Relatórios", path: "/admin/relatorios" },
    { 
      icon: faUserCog, 
      text: "Configurações", 
      path: "/admin/configuracoes",
      submenu: [
        { text: "Perfil", path: "/admin/configuracoes/perfil" },
        { text: "Segurança", path: "/admin/configuracoes/seguranca" },
        { text: "Notificações", path: "/admin/configuracoes/notificacoes" }
      ]
    },
    { icon: faTools, text: "Ferramentas", path: "/admin/ferramentas" },
    { icon: faQuestionCircle, text: "Ajuda", path: "/admin/ajuda" }
  ];

  return (
    <nav className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-header">
        <div className="toggle-button" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div className="sidebar-title">
          {isOpen && <h2>Zenith AI</h2>}
        </div>
        {/* {isOpen && <ZenithLogo width="100" height="100" />} */}
      </div>

      <ul className="sidebar-menu">
        {menuItems.map((item, index) => (
          <li key={index} className={location.pathname === item.path ? "active" : ""}>
            <Link to={item.path} onClick={() => item.submenu && toggleSubmenu(index)}>
              <FontAwesomeIcon icon={item.icon} />
              {isOpen && (
                <>
                  <span>{item.text}</span>
                  {item.submenu && (
                    <FontAwesomeIcon 
                      icon={faAngleDown} 
                      className={`submenu-arrow ${openSubmenu === index ? 'open' : ''}`}
                    />
                  )}
                </>
              )}
            </Link>
            {isOpen && item.submenu && openSubmenu === index && (
              <ul className="submenu">
                {item.submenu.map((subItem, subIndex) => (
                  <li key={subIndex} className={location.pathname === subItem.path ? "active" : ""}>
                    <Link to={subItem.path}>
                      {subItem.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;