import React, { useState } from 'react';
import './Product.css';
import ActionMenu, { Content, Items } from '../../common/ActionMenu';
import Table from '../../common/Table';
import ProdutosModal from './ProductModal';
import { VerificationIcon } from '../components/VerificationIcon';
import { StarRating } from '../components/StartRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"; 

const Product = () => {
  const [produtos, setProdutos] = useState([
    { id: 1, name: 'Camiseta Básica', category: 'Vestuário', verified: true, rating: 4.5, image: 'https://example.com/camiseta.jpg' },
    { id: 2, name: 'Tênis Esportivo', category: 'Calçados', verified: false, rating: 3.0, image: 'https://example.com/tenis.jpg' },
    { id: 3, name: 'Relógio Inteligente', category: 'Acessórios', verified: true, rating: 4.8, image: 'https://example.com/relogio.jpg' },
    { id: 4, name: 'Fone de Ouvido Bluetooth', category: 'Eletrônicos', verified: true, rating: 4.2, image: 'https://example.com/fone.jpg' },
    { id: 5, name: 'Mesa de Escritório', category: 'Móveis', verified: false, rating: 3.5, image: 'https://example.com/mesa.jpg' },
]);


  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterVerified, setFilterVerified] = useState('');
  const [filterImage, setFilterImage] = useState('');
  const [filterRating, setFilterRating] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const filteredProducts = produtos.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = filterCategory ? product.category === filterCategory : true;
    const matchesVerified = filterVerified ? product.verified === (filterVerified === 'true') : true;
    const matchesImage = filterImage ? (product.image ? true : false) : true;
    const matchesRating = filterRating ? product.rating === parseInt(filterRating) : true;

    return matchesSearch && matchesCategory && matchesVerified && matchesImage && matchesRating;
  });

  const columns = [
    { Header: "ID", accessor: "id" },
    {
      Header: "Imagem",
      accessor: "image",
      Cell: ({ row }) => {
        const [imgError, setImgError] = useState(false);
        return imgError ? (
          <FontAwesomeIcon icon={faImage} style={{ fontSize: '50px' }} />
        ) : (
          <img
            src={row.image}
            alt={row.name}
            style={{ width: '50px', height: '50px' }}
            onError={() => setImgError(true)}
          />
        );
      },
    },
    { Header: "Nome", accessor: "name" },
    { Header: "Categoria", accessor: "category" },
    {
      Header: "Verificado",
      accessor: "verified",
      Cell: ({ row }) => <VerificationIcon verified={row.verified} />,
    },
    {
      Header: "Avaliação",
      accessor: "rating",
      Cell: ({ row }) => <StarRating rating={row.rating} />,
    },
    {
      Header: "Ações",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(row)} style={{ cursor: 'pointer', marginRight: '10px' }} />
          <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(row.id)} style={{ cursor: 'pointer', color: 'red' }} />
        </>
      ),
    },
  ];

  const handleAddProduct = () => {
    setCurrentProduct(null);
    setIsModalOpen(true);
  };

  const handleEdit = (product) => {
    console.log(product)
    setCurrentProduct(product);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setProdutos(produtos.filter((product) => product.id !== id));
  };

  const handleModalClose = (newProduct) => {
    if (newProduct) {
      if (currentProduct) {
        setProdutos(produtos.map((product) => (product.id === newProduct.id ? newProduct : product)));
      } else {
        setProdutos([...produtos, { ...newProduct, id: produtos.length + 1 }]);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <div className="produtos-page">
      <header className="produtos-header">
        <h1>Produtos</h1>
        <ActionMenu>
          <Content>Ações</Content>
          <Items>
            <button onClick={handleAddProduct}>Adicionar Produto</button>
            <button>Exportar</button>
            <button>Importar</button>
          </Items>
        </ActionMenu>
      </header>

      <div className="filters">
        <input
          type="search"
          placeholder="Buscar produto..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select onChange={(e) => setFilterCategory(e.target.value)} value={filterCategory} className="filter-select">
          <option value="">Todas</option>
          <option value="Categoria A">Categoria A</option>
          <option value="Categoria B">Categoria B</option>
        </select>
        <select onChange={(e) => setFilterVerified(e.target.value)} value={filterVerified} className="filter-select">
          <option value="">Todos</option>
          <option value="true">Verificados</option>
          <option value="false">Não Verificados</option>
        </select>
        <select onChange={(e) => setFilterImage(e.target.value)} value={filterImage} className="filter-select">
          <option value="">Todos</option>
          <option value="true">Com Imagem</option>
          <option value="false">Sem Imagem</option>
        </select>
        <select onChange={(e) => setFilterRating(e.target.value)} value={filterRating} className="filter-select">
          <option value="">Todas Avaliações</option>
          <option value="1">1 Estrela</option>
          <option value="2">2 Estrelas</option>
          <option value="3">3 Estrelas</option>
          <option value="4">4 Estrelas</option>
          <option value="5">5 Estrelas</option>
        </select>
      </div>

      <Table columns={columns} data={filteredProducts} />
      <ProdutosModal isOpen={isModalOpen} onClose={handleModalClose} product={currentProduct} />
    </div>
  );
};

export default Product;
