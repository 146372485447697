import React, { useState, useRef, useEffect } from "react";
import "./ActionMenu.css";

const ActionMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const content = children.find(child => child.type.name === 'Content');
  const items = children.find(child => child.type.name === 'Items');

  return (
    <div className="action-menu" ref={menuRef}>
      <div className="menu-content" onClick={toggleMenu}>
        {content}
      </div>
      {isOpen && (
        <div className="menu-items">
          {items}
        </div>
      )}
    </div>
  );
};

// Subcomponentes Content e Items
export const Content = ({ children }) => <>{children}</>;
export const Items = ({ children }) => <>{children}</>;

export default ActionMenu;
