import React, { useEffect, useState } from 'react';
import './OverviewModule.css';
import axios from 'axios';

function OverviewModule() {
  const [totalProducts, setTotalProducts] = useState(0);
  const [optimizedProducts, setOptimizedProducts] = useState(0); 
  const [duplicateProducts, setDuplicateProducts] = useState(0); // Estado para cadastros duplicados
  const [overviewData, setOverviewData] = useState({
    totalRevenue: 0.00,
    aiGeneratedDescriptions: 0,
    imageOptimizations: 0,
    categorySuggestions: 0,
    topCategories: [
      { name: 'Roupas', percentage: 0 },
      { name: 'Eletrônicos', percentage: 0 },
      { name: 'Casa e Decoração', percentage: 0 },
      { name: 'Esportes', percentage: 0 },
      { name: 'Beleza', percentage: 0 },
    ],
    recentOptimizations: [
      { name: 'Camiseta Estampada Vintage', improvement: 0 },
      { name: 'Smartwatch Fitness Pro', improvement: 0 },
      { name: 'Conjunto de Panelas Antiaderentes', improvement: 0 },
      { name: 'Tênis de Corrida Ultra Leve', improvement: 0 },
    ],
    performanceMetrics: [
      { name: 'Tempo de Carregamento', value: '-0%' },
      { name: 'Taxa de Rejeição', value: '-0%' },
      { name: 'Tempo Médio na Página', value: '+0%' },
      { name: 'Páginas por Sessão', value: '+0%' },
    ]
  });

  const [topCategories, setTopCategories] = useState([])

  useEffect(() => {
    const fetchTotalProducts = async () => {
      try {
        const response = await axios.get('http://23.21.61.162:3000/api/produtos'); 
        const data = response.data; 
        console.log('data', data)
        setTotalProducts(data.length); 
        setOptimizedProducts(data.filter(prod => prod.verified).length);

        // Lógica para identificar produtos duplicados (baseado, por exemplo, em SKU ou nome)
        const duplicates = data.reduce((acc, product) => {
          acc[product.sku] = (acc[product.sku] || 0) + 1;
          return acc;
        }, {});
        const duplicateCount = Object.values(duplicates).filter(count => count > 1).length;
        setDuplicateProducts(duplicateCount);

        setTopCategories(Object.values(data.reduce((acc, { category }) => {
          if (!acc[category]) acc[category] = { name: category, count: 0 };
          acc[category].count++;
          return acc;
        }, {})).map(({ name, count }) => ({
          name,
          percentage: ((count / data.length) * 100).toFixed(2)
        }))
        .sort((a, b) => b.percentage - a.percentage) 
        .slice(0, 5));

      } catch (error) {
        console.error('Erro ao buscar total de produtos:', error);
      }
    };

    fetchTotalProducts();
  }, []); 

  return (
    <div className="overview-module">      
      <div className="overview-grid">
        <div className="overview-card total-products">
          <h3>Total de Produtos</h3>
          <p className="big-number">{totalProducts.toLocaleString()}</p>
          <p className="sub-text">Cadastrados no sistema</p>
        </div>
        
        <div className="overview-card optimized-products">
          <h3>Produtos Otimizados</h3>
          <p className="big-number">{optimizedProducts.toLocaleString()}</p>
          <p className="sub-text">{((optimizedProducts / totalProducts) * 100).toFixed(1)}% do total</p>
        </div>
        
        <div className="overview-card duplicate-products">
          <h3>Cadastros Duplicados</h3>
          <p className="big-number">{duplicateProducts.toLocaleString()}</p>
          <p className="sub-text">Identificados no sistema</p>
        </div>
        
        <div className="overview-card ratings">
          <h3>Avaliações</h3>
          <p className="big-number">{overviewData.aiGeneratedDescriptions.toLocaleString()}</p>
          <p className="sub-text">Total de avaliações realizadas</p>
        </div>

      </div>

      <div className="overview-grid">
        <div className="overview-card ai-metrics">
          <h3>Métricas de IA</h3>
          <ul>
            <li>
              <span className="metric-name">Descrições Geradas</span>
              <span className="metric-value">{overviewData.aiGeneratedDescriptions.toLocaleString()}</span>
            </li>
            <li>
              <span className="metric-name">Imagens Otimizadas</span>
              <span className="metric-value">{overviewData.imageOptimizations.toLocaleString()}</span>
            </li>
            <li>
              <span className="metric-name">Sugestões de Categoria</span>
              <span className="metric-value">{overviewData.categorySuggestions.toLocaleString()}</span>
            </li>
          </ul>
        </div>

        <div className="overview-card top-categories">
          <h3>Top Categorias</h3>
          <ul>
            {topCategories?.map((category, index) => (
              <li key={index}>
                <span className="category-name">{category.name}</span>
                <div className="category-bar-container">
                  <div className="category-bar" style={{width: `${category.percentage}%`}}></div>
                  <span className="category-percentage">{category.percentage}%</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="overview-grid">
        <div className="overview-card recent-optimizations">
          <h3>Otimizações Recentes</h3>
          <ul>
            {overviewData.recentOptimizations.map((item, index) => (
              <li key={index}>
                <span className="product-name">{item.name}</span>
                <span className="improvement-percentage">+{item.improvement}%</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="overview-card performance-metrics">
          <h3>Métricas de Desempenho</h3>
          <div className="metrics-grid">
            {overviewData.performanceMetrics.map((metric, index) => (
              <div key={index} className="metric-item">
                <h4>{metric.name}</h4>
                <p className={`metric-value ${metric.value.startsWith('+') ? 'positive' : 'negative'}`}>
                  {metric.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverviewModule;
