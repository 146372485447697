import React, { useEffect, useRef, useState } from 'react';
import './Dashboard.css';
import OverviewModule from './OverviewModule';
import ProductsModule from './ProductsModule';
import AnalyticsModule from './AnalyticsModule';

function Dashboard() {
  const canvasRef = useRef(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    const particleCount = 100;

    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(200, 200, 255, 0.5)';
      ctx.beginPath();
      particles.forEach((p) => {
        ctx.moveTo(p.x, p.y);
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2, true);
      });
      ctx.fill();
      moveParticles();
    };

    const moveParticles = () => {
      particles.forEach((p) => {
        p.x += p.dx;
        p.y += p.dy;
        if (p.x < 0 || p.x > canvas.width) p.dx = -p.dx;
        if (p.y < 0 || p.y > canvas.height) p.dy = -p.dy;
      });
    };

    const animate = () => {
      drawParticles();
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
            <OverviewModule/>
        );
      case 'products':
        return (
            <ProductsModule/>
        );
      case 'analytics':
        return (
          <AnalyticsModule/>
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard">
      <canvas ref={canvasRef} className="background-canvas" />
      <header className="dashboard-header">
        <h1>Dashboard</h1>
        <nav className="dashboard-nav">
          <button
            className={activeTab === 'overview' ? 'active' : ''}
            onClick={() => setActiveTab('overview')}
          >
            Visão Geral
          </button>
          <button
            className={activeTab === 'products' ? 'active' : ''}
            onClick={() => setActiveTab('products')}
          >
            Produtos
          </button>
          <button
            className={activeTab === 'analytics' ? 'active' : ''}
            onClick={() => setActiveTab('analytics')}
          >
            Análise
          </button>
        </nav>
      </header>
      <main className="dashboard-content">
        {renderTabContent()}
      </main>
      <footer className="dashboard-footer">
        <p>© 2023 Zenith AI. Todos os direitos reservados.</p>
        <nav>
          <a href="#terms">Termos de Serviço</a>
          <a href="#privacy">Política de Privacidade</a>
        </nav>
      </footer>
    </div>
  );
}

export default Dashboard;