import React, { useEffect, useRef, useState } from "react";
import "./ProductModal.css";
import TagList from "../../common/TagList";
import InputFile from "../../common/InputFIle";
import ImageGrid from "../../common/ImageGrid";

function ProductModal({ isOpen, onClose }) {
  const modalRef = useRef();
  const initialValues = useRef();
  const [tags, setTags] = useState([]);
  const [atributos, setAtributos] = useState([]);
  const [imagens, setImagens] = useState([]); // Estado para as imagens


  useEffect(() => {
    if (isOpen) {
      initialValues.current = {
        sku: '',
        nome: '',
        descricao: '',
        categoria: '',
        subcategoria: '',
        imagens: [],
        peso: '',
        dimensoes: { altura: '', largura: '', profundidade: '' },
        estoque: '',
        atributos: [],
        marca: '',
        tags: [],
        dataLancamento: '',
      };
      setTags([]); // Resetando as tags
      setAtributos([]); // Resetando atributos
    }

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleImageUpload = (event) => {
    setImagens([...event.target.files]);
  };
  
  const handleImageDelete = (index) => {
    setImagens(imagens.filter((_, i) => i !== index)); // Remove a imagem pelo índice
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-title">Cadastro de Produto</h2>

        <div className="field-group">
          <h3>Informações Básicas</h3>
          <label>Nome:</label>
          <input type="text" placeholder="Ex: SABONETE DOVE 90G..."/>
          <label>Descrição do Produto:</label>
          <textarea placeholder="Ex: Um sabonete hidratante com perfume suave." />
          <label>SKU:</label>
          <TagList tags={tags} setTags={setTags} placeholder={'Ex: 12345678, 910111213'}/>
        </div>

        <div className="field-group">
          <h3>Categoria</h3>
          <label>Categoria:</label>
          <input type="text" placeholder="Ex: Higiene Pessoal" />
          <label>Subcategoria:</label>
          <input type="text" placeholder="Ex: Sabonetes" />
        </div>

        <div className="field-group">
          <h3>Imagens</h3>
          <InputFile accept="image/*" multiple={true} onChange={handleImageUpload} />
          <ImageGrid images={imagens} onDelete={handleImageDelete} /> 
        </div>

        <div className="field-group">
          <h3>Características do Produto</h3>
          <label>Peso:</label>
          <input type="text" placeholder="Ex: 90g" />
          <label>Dimensões:</label>
          <div className="dimensions">
            <input type="text" placeholder="Altura (cm)" />
            <input type="text" placeholder="Largura (cm)" />
            <input type="text" placeholder="Profundidade (cm)" />
          </div>
        </div>

        <div className="field-group">
          <h3>Atributos</h3>
          <TagList tags={atributos} setTags={setAtributos} />
        </div>

        <div className="field-group">
          <h3>Informações Adicionais</h3>
          <label>Marca:</label>
          <input type="text" placeholder="Ex: Dove" />
          <label>Data de Lançamento:</label>
          <input type="date" />
        </div>

        <div className="modal-buttons">
          <button type="cancel" onClick={onClose}>Cancelar</button>
          <button type="save">Salvar</button>
        </div>
      </div>
    </div>
  );
}

export default ProductModal;
