import React, { useState } from 'react';
import './InputFile.css';

const InputFile = ({ onChange, multiple = false, ...props }) => {
  const [fileCount, setFileCount] = useState(0);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFileCount(files.length);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="file-upload">
      <input
        type="file"
        id="file-upload"
        onChange={handleFileChange}
        multiple={multiple}
        {...props}
      />
      <label htmlFor="file-upload" className="custom-file-upload"/>
      <span>{fileCount <= 0 ? "Selecionar arquivo" : (fileCount === 1 ? `1 arquivo selecionado` : `${fileCount} arquivos selecionados`)}</span>
    </div>
  );
};

export default InputFile;
