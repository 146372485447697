import React, { useState, useEffect } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    productCount: '',
    currentPlatform: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode adicionar a lógica para enviar os dados do formulário
    console.log('Dados do formulário:', formData);
    // Limpar o formulário após o envio
    setFormData({
      name: '',
      email: '',
      company: '',
      productCount: '',
      currentPlatform: '',
      message: ''
    });
    alert('Solicitação de proposta enviada com sucesso!');
  };

  useEffect(() => {
    const canvas = document.getElementById('backgroundCanvas');
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    const particleCount = 100;

    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(200, 200, 255, 0.5)';
      ctx.beginPath();
      particles.forEach((p) => {
        ctx.moveTo(p.x, p.y);
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2, true);
      });
      ctx.fill();
      moveParticles();
    };

    const moveParticles = () => {
      particles.forEach((p) => {
        p.x += p.dx;
        p.y += p.dy;
        if (p.x < 0 || p.x > canvas.width) p.dx = -p.dx;
        if (p.y < 0 || p.y > canvas.height) p.dy = -p.dy;
      });
    };

    const animate = () => {
      drawParticles();
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="App">
      <canvas id="backgroundCanvas" className="background-canvas" />
      <main className="full-screen">
        <section className="contact-form">
          <h1>Solicite uma Proposta</h1>
          <p>Preencha o formulário abaixo para receber uma proposta personalizada do Zenith AI.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Empresa</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="productCount">Quantidade aproximada de produtos</label>
              <input
                type="number"
                id="productCount"
                name="productCount"
                value={formData.productCount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="currentPlatform">Plataforma de e-commerce atual</label>
              <input
                type="text"
                id="currentPlatform"
                name="currentPlatform"
                value={formData.currentPlatform}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensagem adicional</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>
            <button type="submit" className="primary">Enviar Solicitação</button>
          </form>
        </section>
      </main>
      <footer>
        <p>© 2023 Zenith AI. Todos os direitos reservados.</p>
        <nav>
          <a href="#terms">Termos de Serviço</a>
          <a href="#privacy">Política de Privacidade</a>
        </nav>
      </footer>
    </div>
  );
}

export default Contact;