import { Route, Routes } from "react-router-dom";
import Header from "../components/landingPage/components/Header";
import Documentation from "../components/landingPage/modules/Documentation";
import Examples from "../components/landingPage/modules/Examples";
import Plans from "../components/landingPage/modules/Plans";
import Login from "../components/landingPage/modules/Login";
import Contact from "../components/landingPage/modules/Contact";
import Payment from "../components/landingPage/modules/Payment";

export default function LandingPage({onLogin}) {

    return (
      <div className="app-landing-page-layout">
        <Header />
        <Routes>
          <Route path="/" element={<Documentation />} />
          {/* <Route path="/examples" element={<Examples />} /> */}
          <Route path="/plans" element={<Plans />} />
          <Route path="/login" element={<Login onLogin={onLogin}/>} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/payment" element={<Payment/>}/> 
        </Routes>
      </div>
    );

}