import React, { useState, useEffect } from 'react';
import './ProductsModule.css';

function ProductsModule() {
  // Fictional product data
  const products = [
    { id: 1, name: 'Camiseta Estampada Vintage', category: 'Roupas', status: 'Otimizado', optimizationScore: 95, lastUpdated: '2023-05-15', changeFrequency: 'Baixa' },
    { id: 2, name: 'Tênis Esportivo Ultra Leve', category: 'Calçados', status: 'Pendente', optimizationScore: 60, lastUpdated: '2023-05-10', changeFrequency: 'Alta' },
    { id: 3, name: 'Smartwatch Fitness Pro', category: 'Eletrônicos', status: 'Otimizado', optimizationScore: 98, lastUpdated: '2023-05-18', changeFrequency: 'Média' },
    { id: 4, name: 'Conjunto de Panelas Antiaderentes', category: 'Casa e Decoração', status: 'Em Progresso', optimizationScore: 75, lastUpdated: '2023-05-12', changeFrequency: 'Baixa' },
    { id: 5, name: 'Mochila Impermeável para Notebooks', category: 'Acessórios', status: 'Otimizado', optimizationScore: 92, lastUpdated: '2023-05-17', changeFrequency: 'Média' },
    { id: 6, name: 'Fone de Ouvido Bluetooth', category: 'Eletrônicos', status: 'Pendente', optimizationScore: 55, lastUpdated: '2023-05-08', changeFrequency: 'Alta' },
    { id: 7, name: 'Kit de Skincare Hidratante', category: 'Beleza', status: 'Em Progresso', optimizationScore: 80, lastUpdated: '2023-05-14', changeFrequency: 'Média' },
    { id: 8, name: 'Cadeira Ergonômica para Home Office', category: 'Móveis', status: 'Otimizado', optimizationScore: 97, lastUpdated: '2023-05-16', changeFrequency: 'Baixa' },
    { id: 9, name: 'Livro "IA: O Futuro é Agora"', category: 'Livros', status: 'Pendente', optimizationScore: 50, lastUpdated: '2023-05-09', changeFrequency: 'Baixa' },
    { id: 10, name: 'Cafeteira Programável', category: 'Eletrodomésticos', status: 'Em Progresso', optimizationScore: 85, lastUpdated: '2023-05-13', changeFrequency: 'Média' },
  ];

  const [categoryDistribution, setCategoryDistribution] = useState({});
  const [optimizationDistribution, setOptimizationDistribution] = useState({});
  const [changeFrequencyDistribution, setChangeFrequencyDistribution] = useState({});
  const [recentlyUpdatedProducts, setRecentlyUpdatedProducts] = useState([]);

  useEffect(() => {
    analyzeProducts();
  }, []);

  const analyzeProducts = () => {
    const categories = {};
    const optimizationLevels = { 'Baixo': 0, 'Médio': 0, 'Alto': 0 };
    const changeFrequencies = { 'Baixa': 0, 'Média': 0, 'Alta': 0 };

    products.forEach(product => {
      // Category distribution
      categories[product.category] = (categories[product.category] || 0) + 1;

      // Optimization score distribution
      if (product.optimizationScore < 70) optimizationLevels['Baixo']++;
      else if (product.optimizationScore < 90) optimizationLevels['Médio']++;
      else optimizationLevels['Alto']++;

      // Change frequency distribution
      changeFrequencies[product.changeFrequency]++;
    });

    setCategoryDistribution(categories);
    setOptimizationDistribution(optimizationLevels);
    setChangeFrequencyDistribution(changeFrequencies);

    // Sort products by last updated date and get top 5
    const sortedProducts = [...products].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
    setRecentlyUpdatedProducts(sortedProducts.slice(0, 5));
  };

  const renderDistributionChart = (data, title) => {
    const total = Object.values(data).reduce((sum, value) => sum + value, 0);
    return (
      <div className="distribution-chart">
        <h3>{title}</h3>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} className="distribution-bar">
            <div className="bar-label">{key}</div>
            <div className="bar-container">
              <div className="bar" style={{ width: `${(value / total) * 100}%` }}></div>
            </div>
            <div className="bar-value">{((value / total) * 100).toFixed(1)}%</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="products-module">
      {/* <h2>Análise de Produtos</h2> */}
      <div className="dashboard-grid">
        {renderDistributionChart(categoryDistribution, 'Distribuição por Categoria')}
        {renderDistributionChart(optimizationDistribution, 'Níveis de Otimização')}
        {renderDistributionChart(changeFrequencyDistribution, 'Frequência de Alterações')}
        <div className="recent-updates">
          <h3>Atualizações Recentes</h3>
          <ul>
            {recentlyUpdatedProducts.map(product => (
              <li key={product.id}>
                <span className="product-name">{product.name}</span>
                <span className="update-date">{product.lastUpdated}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductsModule;