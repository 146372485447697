import React, { useState } from 'react';
import './AnalyticsModule.css';

function AnalyticsModule() {
  const [timeRange, setTimeRange] = useState('30d');

  // Fictional analytics data based on Documentation.js content
  const analyticsData = {
    totalProducts: 1234,
    averageOptimizationScore: 78.5,
    productRegistrationRate: 4.7,
    topOptimizedProducts: [
      { name: 'Smartwatch Fitness Pro', score: 95, improvement: 15 },
      { name: 'Camiseta Estampada Vintage', score: 92, improvement: 12 },
      { name: 'Fone de Ouvido Bluetooth', score: 90, improvement: 18 },
      { name: 'Tênis Esportivo Ultra Leve', score: 88, improvement: 10 },
      { name: 'Mochila Impermeável', score: 87, improvement: 14 }
    ],
    categoryPerformance: [
      { name: 'Eletrônicos', percentage: 35, averageScore: 82 },
      { name: 'Roupas', percentage: 25, averageScore: 79 },
      { name: 'Calçados', percentage: 20, averageScore: 77 },
      { name: 'Acessórios', percentage: 15, averageScore: 75 },
      { name: 'Casa e Decoração', percentage: 5, averageScore: 73 }
    ],
    optimizationImpact: {
      overallScoreIncrease: 18,
      productRegistrationIncrease: 25,
      averageOptimizationTimeDecrease: 15
    },
    dailyRegistrations: [
      { date: '2023-05-01', count: 41 },
      { date: '2023-05-02', count: 38 },
      { date: '2023-05-03', count: 52 },
      { date: '2023-05-04', count: 47 },
      { date: '2023-05-05', count: 59 },
      { date: '2023-05-06', count: 63 },
      { date: '2023-05-07', count: 58 }
    ]
  };

  return (
    <div className="analytics-module">
      {/* <h2>Análise de Desempenho</h2> */}
      
      <div className="time-range-selector">
        <button className={timeRange === '7d' ? 'active' : ''} onClick={() => setTimeRange('7d')}>7 dias</button>
        <button className={timeRange === '30d' ? 'active' : ''} onClick={() => setTimeRange('30d')}>30 dias</button>
        <button className={timeRange === '90d' ? 'active' : ''} onClick={() => setTimeRange('90d')}>90 dias</button>
      </div>

      <div className="analytics-grid">
        <div className="analytics-card total-products">
          <h3>Total de Produtos</h3>
          <p className="big-number">{analyticsData.totalProducts}</p>
          <p className="sub-text">+25% vs. período anterior</p>
        </div>
        
        <div className="analytics-card average-score">
          <h3>Pontuação Média de Otimização</h3>
          <p className="big-number">{analyticsData.averageOptimizationScore.toFixed(1)}</p>
          <p className="sub-text">+5 pontos vs. período anterior</p>
        </div>
        
        <div className="analytics-card registration-rate">
          <h3>Taxa de Registro de Produtos</h3>
          <p className="big-number">{analyticsData.productRegistrationRate.toFixed(1)}/dia</p>
          <p className="sub-text">+0.5 vs. período anterior</p>
        </div>
      </div>

      <div className="analytics-row">
        <div className="analytics-card top-products">
          <h3>Produtos Mais Otimizados</h3>
          <table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Pontuação</th>
                <th>Melhoria</th>
              </tr>
            </thead>
            <tbody>
              {analyticsData.topOptimizedProducts.map((product, index) => (
                <tr key={index}>
                  <td>{product.name}</td>
                  <td>{product.score}</td>
                  <td className="positive">+{product.improvement}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="analytics-card category-performance">
          <h3>Desempenho por Categoria</h3>
          {analyticsData.categoryPerformance.map((category, index) => (
            <div key={index} className="category-bar">
              <div className="category-name">{category.name}</div>
              <div className="category-progress-container">
                <div 
                  className="category-progress" 
                  style={{width: `${category.percentage}%`}}
                ></div>
              </div>
              <div className="category-percentage">{category.percentage}%</div>
              <div className="category-score">
                Score: {category.averageScore}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="analytics-row">
        <div className="analytics-card optimization-impact">
          <h3>Impacto da Otimização</h3>
          <div className="impact-grid">
            <div className="impact-item">
              <h4>Aumento na Pontuação Geral</h4>
              <p className="impact-value positive">+{analyticsData.optimizationImpact.overallScoreIncrease}%</p>
            </div>
            <div className="impact-item">
              <h4>Aumento no Registro de Produtos</h4>
              <p className="impact-value positive">+{analyticsData.optimizationImpact.productRegistrationIncrease}%</p>
            </div>
            <div className="impact-item">
              <h4>Redução no Tempo Médio de Otimização</h4>
              <p className="impact-value positive">-{analyticsData.optimizationImpact.averageOptimizationTimeDecrease}%</p>
            </div>
          </div>
        </div>

        <div className="analytics-card registration-chart">
          <h3>Registros Diários de Produtos</h3>
          <div className="chart-container">
            {analyticsData.dailyRegistrations.map((day, index) => (
              <div key={index} className="chart-bar" style={{height: `${(day.count / 70) * 100}%`}}>
                <div className="chart-tooltip">{day.count} produtos</div>
              </div>
            ))}
          </div>
          <div className="chart-labels">
            {analyticsData.dailyRegistrations.map((day, index) => (
              <div key={index} className="chart-label">{new Date(day.date).getDate()}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsModule;