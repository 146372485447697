import React from "react";
import "./Header.css";
import Dropdown from "../../common/Dropdown";
import { faHome, faUser, faCog, faU, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import ActionMenu, { Content, Items } from "../../common/ActionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Header() {
  
  return (
    <header className="admin-header">
      <h1></h1>
      <div className="user-info">

        <ActionMenu>
          <Content>
            <div>
              <span>Junior Oliveira</span>
              <FontAwesomeIcon icon={faUser} className="icon" />
            </div>
          </Content>

          <Items>
            <div>
              <FontAwesomeIcon icon={faCog} className="icon" /> Configurações
            </div>
            <div>
              <FontAwesomeIcon icon={faSignOutAlt} className="icon" /> Sair
            </div>
          </Items>
        </ActionMenu>
      </div>
    </header>
  );
}

export default Header;
