import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import "./Admin.css";
import Sidebar from "../components/admin/components/Sidebar";
import Header from "../components/admin/components/Header";
import Product from "../components/admin/modules/Product";
import Dashboard from "../components/admin/modules/Dashboard";
import ConfiguracoesIA from "../components/admin/modules/ConfiguracoesIA";
import ConfigurationsProductOptimization from "../components/admin/modules/ConfigurationsProductOptimization";
import ConfigurationsImageAnalysis from "../components/admin/modules/ConfigurationsImageAnalysis";
import Configurations from "../components/admin/modules/Configurations";

function Admin() {
  return (
    <div className="admin-container">
      <Sidebar />
      <div className="admin-content">
        <div className="admin-header-content">
          <Header />
        </div>
        <div className="admin-routes-content">
          <Routes>
            <Route path="/admin/produtos" element={<Product />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/ferramentas" element={<ConfiguracoesIA />} />
            <Route path="/admin/configuracoes" element={<Configurations />} />
            <Route path="/admin/ajuda" element={<ConfigurationsImageAnalysis />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Admin;
