import React, { useEffect, useRef } from "react";
import "./Documentation.css";

function Documentation() {
  const canvasRef = useRef(null);
  const featuresRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    const particles = [];
    const particleCount = 100;

    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(200, 200, 255, 0.5)";
      ctx.beginPath();
      particles.forEach((p) => {
        ctx.moveTo(p.x, p.y);
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2, true);
      });
      ctx.fill();
      moveParticles();
    };

    const moveParticles = () => {
      particles.forEach((p) => {
        p.x += p.dx;
        p.y += p.dy;
        if (p.x < 0 || p.x > canvas.width) p.dx = -p.dx;
        if (p.y < 0 || p.y > canvas.height) p.dy = -p.dy;
      });
    };

    const animate = () => {
      drawParticles();
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();

    // Grid hover effect
    const features = featuresRef.current;
    const featureCards = features.querySelectorAll(".feature");

    featureCards.forEach((card) => {
      const grid = document.createElement("div");
      grid.classList.add("grid-hover");
      for (let i = 0; i < 100; i++) {
        const cell = document.createElement("div");
        cell.classList.add("grid-cell");
        grid.appendChild(cell);
      }
      card.appendChild(grid);

      card.addEventListener("mousemove", (e) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        grid.style.setProperty("--mouse-x", `${x}px`);
        grid.style.setProperty("--mouse-y", `${y}px`);
      });

      card.addEventListener("mouseleave", () => {
        grid.style.setProperty("--mouse-x", "0px");
        grid.style.setProperty("--mouse-y", "0px");
      });
    });

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="App">
      <canvas ref={canvasRef} className="background-canvas" />
      <main>
        <section className="hero full-screen">
          <h1>Bem-vindo ao Zenith AI</h1>
          <p>
            Revolucione seus cadastros de produtos com nossa API de otimização inteligente,
            criando descrições envolventes e maximizando suas vendas.
          </p>
          <div className="cta-buttons">
            <button className="primary">Iniciar Agora</button>
            <button className="secondary">Explorar Documentação</button>
          </div>
        </section>

        <section className="features full-screen" ref={featuresRef}>
          <h2>Recursos Inovadores</h2>
          <div className="feature-grid">
            <div className="feature">
              <h3>Otimização Inteligente de Cadastros</h3>
              <p>
                Aprimore a qualidade e completude das informações dos seus produtos,
                garantindo que todos os detalhes essenciais estejam presentes e
                organizados de forma otimizada para mecanismos de busca e conversão.
              </p>
            </div>

            <div className="feature">
              <h3>Geração de Descrições Persuasivas</h3>
              <p>
                Crie descrições de produtos cativantes e persuasivas que destacam
                os principais benefícios e características, utilizando técnicas
                avançadas de copywriting e IA para aumentar o engajamento e as vendas.
              </p>
            </div>

            <div className="feature">
              <h3>Validação e Otimização de Imagens</h3>
              <p>
                Analise e aprimore automaticamente as imagens dos seus produtos,
                garantindo qualidade, conformidade e atratividade visual. Optimize
                o tamanho e formato para carregamento rápido sem perder qualidade.
              </p>
            </div>

            <div className="feature">
              <h3>Categorização Inteligente</h3>
              <p>
                Classifique seus produtos em categorias e subcategorias relevantes
                de forma automática, melhorando a navegabilidade do seu e-commerce
                e facilitando a descoberta de produtos pelos clientes.
              </p>
            </div>

            <div className="feature">
              <h3>Análise Avançada de Dados</h3>
              <p>
                Obtenha insights valiosos sobre o desempenho dos seus cadastros,
                identificando oportunidades de melhoria, tendências de mercado e
                estratégias para otimização contínua do seu catálogo de produtos.
              </p>
            </div>

            <div className="feature explore-more">
              <h3>Explorar Mais Funcionalidades</h3>
              <p>
                Descubra recursos adicionais e personalizações avançadas para
                impulsionar ainda mais o seu negócio com o Zenith AI.
              </p>
              <button className="explore-button">Saiba Mais</button>
            </div>
          </div>
        </section>

        <section className="code-example full-screen">
          <h2>Integração Simples e Poderosa</h2>
          <pre>
            <code>{`import { ZenithAI } from 'zenith-ai';

// Configuração da API Zenith AI
const zenith = new ZenithAI('sua-chave-de-api');

// Exemplo de produto para otimização
const produto = {
  nome: 'Camiseta Estampada Vintage',
  descricao: 'Camiseta 100% algodão com estampa retrô.',
  categoria: 'Roupas',
  imagem: 'https://exemplo.com/imagem-camiseta.jpg',
};

// Uso da API para otimização completa do cadastro
const resultado = await zenith.otimizarCadastro(produto);
console.log('Cadastro otimizado:', resultado);

// Geração de descrição persuasiva
const novaDescricao = await zenith.gerarDescricao(produto);
console.log('Nova descrição:', novaDescricao);
`}</code>
          </pre>
        </section>
      </main>

      <footer>
        <p>© 2023 Zenith AI. Todos os direitos reservados.</p>
        <nav>
          <a href="#terms">Termos de Serviço</a>
          <a href="#privacy">Política de Privacidade</a>
        </nav>
      </footer>
    </div>
  );
}

export default Documentation;