import React, { useState } from 'react';
import './ConfigurationsImageAnalysis.css';

function ConfigurationsImageAnalysis() {
  const [activeTab, setActiveTab] = useState('general');
  const [properties, setProperties] = useState([]);
  const [newProperty, setNewProperty] = useState({ name: '', type: 'string', description: '', group: 'General' });
  const [isAddingProperty, setIsAddingProperty] = useState(false);
  const [groups] = useState(['General', 'Objects', 'Faces', 'Text', 'Colors']);
  const [analysisSettings, setAnalysisSettings] = useState({
    model: 'vision-v3',
    minConfidence: 0.7,
    maxResults: 50,
  });
  const [isJsonPreviewOpen, setIsJsonPreviewOpen] = useState(false);

  const addProperty = () => {
    if (newProperty.name) {
      setProperties([...properties, { ...newProperty, id: Date.now().toString() }]);
      setNewProperty({ name: '', type: 'string', description: '', group: 'General' });
      setIsAddingProperty(false);
    }
  };

  const removeProperty = (id) => {
    setProperties(properties.filter(prop => prop.id !== id));
  };

  const handlePropertyChange = (id, field, value) => {
    setProperties(properties.map(prop => 
      prop.id === id ? { ...prop, [field]: value } : prop
    ));
  };

  const exportConfig = () => {
    const config = {
      properties,
      groups,
      analysisSettings,
    };
    const blob = new Blob([JSON.stringify(config, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'image-analysis-config.json';
    a.click();
  };

  const importConfig = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const config = JSON.parse(e.target.result);
          setProperties(config.properties || []);
          setAnalysisSettings(config.analysisSettings || {
            model: 'vision-v3',
            minConfidence: 0.7,
            maxResults: 50,
          });
        } catch (error) {
          console.error('Error parsing config file:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const generateJsonPreview = () => {
    return JSON.stringify({
      analysisSettings,
      results: {
        objects: [
          { name: "car", confidence: 0.95, boundingBox: { x: 10, y: 20, width: 100, height: 50 } },
          { name: "person", confidence: 0.88, boundingBox: { x: 150, y: 30, width: 80, height: 160 } }
        ],
        faces: [
          { age: 30, gender: "female", emotion: "happy", boundingBox: { x: 200, y: 50, width: 60, height: 60 } }
        ],
        text: [
          { content: "STOP", confidence: 0.99, boundingBox: { x: 300, y: 100, width: 80, height: 40 } }
        ],
        colors: [
          { color: "#FF0000", percentage: 0.3 },
          { color: "#00FF00", percentage: 0.2 },
          { color: "#0000FF", percentage: 0.5 }
        ],
        ...properties.reduce((acc, prop) => {
          acc[prop.name] = `Sample ${prop.type} value`;
          return acc;
        }, {})
      }
    }, null, 2);
  };

  return (
    <div className="config-image-analysis">
      <h2 className="config-title">Configurações de Análise de Imagens</h2>

      <div className="config-layout">
        <div className="config-sidebar">
          <button 
            className={`sidebar-button ${activeTab === 'general' ? 'active' : ''}`}
            onClick={() => setActiveTab('general')}
          >
            Geral
          </button>
          <button 
            className={`sidebar-button ${activeTab === 'analysis' ? 'active' : ''}`}
            onClick={() => setActiveTab('analysis')}
          >
            Análise
          </button>
          <button 
            className={`sidebar-button ${activeTab === 'properties' ? 'active' : ''}`}
            onClick={() => setActiveTab('properties')}
          >
            Propriedades
          </button>
        </div>

        <div className="config-content">
          {activeTab === 'general' && (
            <div className="config-section">
              <h3 className="section-title">Configurações Gerais</h3>
              <div className="form-group">
                <label htmlFor="apiKey">Chave da API</label>
                <input type="password" id="apiKey" className="input" />
              </div>
              <div className="form-group">
                <label htmlFor="endpoint">Endpoint da API</label>
                <input type="text" id="endpoint" className="input" placeholder="https://api.example.com/analyze" />
              </div>
            </div>
          )}

          {activeTab === 'analysis' && (
            <div className="config-section">
              <h3 className="section-title">Configurações de Análise</h3>
              <div className="form-group">
                <label htmlFor="analysisModel">Modelo de Análise</label>
                <select 
                  id="analysisModel"
                  value={analysisSettings.model}
                  onChange={(e) => setAnalysisSettings({...analysisSettings, model: e.target.value})}
                  className="select"
                >
                  <option value="vision-v3">Vision V3</option>
                  <option value="vision-v4">Vision V4</option>
                  <option value="custom-model">Modelo Personalizado</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="minConfidence">Confiança Mínima: {analysisSettings.minConfidence}</label>
                <input
                  type="range"
                  id="minConfidence"
                  min="0"
                  max="1"
                  step="0.05"
                  value={analysisSettings.minConfidence}
                  onChange={(e) => setAnalysisSettings({...analysisSettings, minConfidence: parseFloat(e.target.value)})}
                  className="slider"
                />
              </div>
              <div className="form-group">
                <label htmlFor="maxResults">Máximo de Resultados</label>
                <input 
                  id="maxResults"
                  type="number"
                  value={analysisSettings.maxResults}
                  onChange={(e) => setAnalysisSettings({...analysisSettings, maxResults: parseInt(e.target.value)})}
                  className="input"
                />
              </div>
              <div className="form-group">
                <label>Tipos de Análise</label>
                <div className="checkbox-group">
                  <label><input type="checkbox" defaultChecked /> Detecção de Objetos</label>
                  <label><input type="checkbox" defaultChecked /> Reconhecimento Facial</label>
                  <label><input type="checkbox" defaultChecked /> OCR (Texto)</label>
                  <label><input type="checkbox" defaultChecked /> Análise de Cores</label>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'properties' && (
            <div className="config-section">
              <h3 className="section-title">Propriedades Personalizadas do JSON</h3>
              <div className="properties-list">
                {properties.map((prop) => (
                  <div key={prop.id} className="property-item">
                    <div className="property-header">
                      <span className="property-name">{prop.name}</span>
                      <button 
                        onClick={() => removeProperty(prop.id)}
                        className="remove-button"
                      >
                        X
                      </button>
                    </div>
                    <div className="property-content">
                      <div className="property-row">
                        <select 
                          value={prop.type} 
                          onChange={(e) => handlePropertyChange(prop.id, 'type', e.target.value)}
                          className="select small"
                        >
                          <option value="string">Texto</option>
                          <option value="number">Número</option>
                          <option value="boolean">Sim/Não</option>
                          <option value="array">Array</option>
                          <option value="object">Objeto</option>
                        </select>
                        <select 
                          value={prop.group} 
                          onChange={(e) => handlePropertyChange(prop.id, 'group', e.target.value)}
                          className="select small"
                        >
                          {groups.map((group) => (
                            <option key={group} value={group}>{group}</option>
                          ))}
                        </select>
                      </div>
                      <input 
                        value={prop.description} 
                        onChange={(e) => handlePropertyChange(prop.id, 'description', e.target.value)}
                        className="input"
                        placeholder="Descrição"
                      />
                    </div>
                  </div>
                ))}
              </div>
              {isAddingProperty ? (
                <div className="new-property-form">
                  <input 
                    placeholder="Nome da propriedade" 
                    value={newProperty.name} 
                    onChange={(e) => setNewProperty({...newProperty, name: e.target.value})}
                    className="input"
                  />
                  <div className="property-row">
                    <select 
                      value={newProperty.type} 
                      onChange={(e) => setNewProperty({...newProperty, type: e.target.value})}
                      className="select small"
                    >
                      <option value="string">Texto</option>
                      <option value="number">Número</option>
                      <option value="boolean">Sim/Não</option>
                      <option value="array">Array</option>
                      <option value="object">Objeto</option>
                    </select>
                    <select 
                      value={newProperty.group} 
                      onChange={(e) => setNewProperty({...newProperty, group: e.target.value})}
                      className="select small"
                    >
                      {groups.map((group) => (
                        <option key={group} value={group}>{group}</option>
                      ))}
                    </select>
                  </div>
                  <input 
                    placeholder="Descrição da propriedade" 
                    value={newProperty.description} 
                    onChange={(e) => setNewProperty({...newProperty, description: e.target.value})}
                    className="input"
                  />
                  <div className="button-group">
                    <button onClick={() => setIsAddingProperty(false)} className="cancel-button">
                      Cancelar
                    </button>
                    <button onClick={addProperty} className="add-button">
                      Adicionar
                    </button>
                  </div>
                </div>
              ) : (
                <button onClick={() => setIsAddingProperty(true)} className="add-property-button">
                  + Adicionar Nova Propriedade
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="action-buttons">
        <button onClick={exportConfig} className="action-button">
          Exportar Configurações
        </button>
        <label htmlFor="import-config" className="action-button">
          Importar Configurações
        </label>
        <input
          id="import-config"
          type="file"
          accept=".json"
          style={{ display: 'none' }}
          onChange={importConfig}
        />
        <button onClick={() => setIsJsonPreviewOpen(true)} className="action-button">
          Visualizar JSON
        </button>
      </div>

      <button className="save-button">
        Salvar Configurações
      </button>

      {isJsonPreviewOpen && (
        <div className="dialog-overlay">
          <div className="dialog-content">
            <h3 className="dialog-title">Visualização do JSON</h3>
            <p className="dialog-description">
              Este é um exemplo de como o JSON de retorno pode ser estruturado com base nas suas configurações atuais.
            </p>
            <div className="json-preview">
              <pre>{generateJsonPreview()}</pre>
            </div>
            <button onClick={() => setIsJsonPreviewOpen(false)} className="close-dialog-button">
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfigurationsImageAnalysis;