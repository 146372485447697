import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/svg/icons/ZenithLogo.svg'
import './Header.css';

function Header() {
  return (
    <header>
      <div className="logo">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"> */}
        {/* </svg> */}
          {/* <path d="m8 3 4 8 5-5 5 15H2L8 3z" /> */}
          <img width={35} src={logo}/> 
        <span>
          Zenith
        </span>
      </div>
      <nav>
        <Link to="/">Documentação</Link>
        {/* <Link to="/examples">Exemplos</Link> */}
        <Link to="/plans">Planos</Link>
        {/* <Link to="/login">Login</Link> */}
      </nav>
    </header>
  );
}

export default Header;
